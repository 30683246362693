<template>
  <div>
    <div class="card-body px-lg-6 pb-0">
      <div class="border-bottom pb-4">
        <div class="row align-items-start">
          <div class="col-12 col-print-9">
            <h1>{{ meeting.title }}</h1>

            <h3 class="mb-3">{{ meeting.company_title }}</h3>

            <table class="table table-sm mb-4">
              <tbody>
                <tr v-if="meetingSeriesEnabled">
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{
                      $t(
                        `activerecord.attributes.meeting.meeting_series_type`,
                        company.locale
                      )
                    }}
                  </th>

                  <td class="pb-0">
                    {{
                      $t(
                        `models.meeting.meeting_series_type.${meeting.meeting_series_type}.other`,
                        company.locale
                      )
                    }}
                  </td>
                </tr>

                <tr>
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{
                      $t(
                        "activerecord.attributes.meeting.number",
                        company.locale
                      )
                    }}
                  </th>

                  <td class="pb-0">{{ meeting.number }}</td>
                </tr>

                <tr>
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{
                      $t(
                        "activerecord.attributes.meeting.theme",
                        company.locale
                      )
                    }}
                  </th>

                  <td class="pb-0">{{ meeting.theme }}</td>
                </tr>

                <tr>
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{ $t("models.meeting.date_and_time", company.locale) }}
                  </th>

                  <td class="pb-0">
                    <template v-if="meeting.start_at">
                      {{ formattedDateRange }}
                    </template>
                  </td>
                </tr>

                <tr>
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{
                      $t(
                        "activerecord.attributes.meeting.location",
                        company.locale
                      )
                    }}
                  </th>

                  <td class="pb-0">{{ meeting.location }}</td>
                </tr>

                <tr v-if="isAgenda">
                  <th class="pl-0 pb-0 pr-4 col-shrink">
                    {{
                      $t(
                        "activerecord.attributes.meeting.duration",
                        company.locale
                      )
                    }}
                  </th>

                  <td class="pb-0">
                    {{ meetingDuration }}
                    <i
                      v-if="scheduledIsTooShort"
                      v-be-tooltip="
                        $t(
                          'components.meetings.material.editor.meeting_longer_than_scheduled',
                          company.locale
                        )
                      "
                      class="fa-duotone fa-triangle-exclamation text-warning ml-1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="$currentCompany.logo_url"
            class="d-none col-print-3 d-print-flex justify-content-end"
          >
            <be-img
              class="max-vh-15 object-fit-contain mt-1"
              :src="$currentCompany.logo_url"
              :alt="$currentCompany.title"
            />
          </div>
        </div>

        <minutes-attendance-display v-if="isMinutes" />

        <agenda-invitations-display v-else />
      </div>
    </div>

    <div class="card-body px-lg-6 pt-lg-4">
      <h2 class="mb-4">
        {{
          materialType
            ? $t(
                `activerecord.attributes.material.${materialType}`,
                company.locale
              )
            : ""
        }}
      </h2>

      <div class="table-responsive">
        <table class="w-100">
          <tbody>
            <template
              v-for="item in items"
              :key="`item-rows-${item.id || item.uuid}`"
            >
              <tr>
                <td class="col-shrink">
                  <h3 class="pl-0 mb-1 mr-1">{{ item.number }}.</h3>
                </td>

                <td>
                  <div class="d-flex justify-content-between">
                    <h3 class="mb-1">{{ item.title }}</h3>

                    <div v-if="withMeetingOverviewComments">
                      <toggle-comments-button
                        :reference="{
                          type: 'Item',
                          id: item.id,
                          number: item.number,
                        }"
                        class="d-none btn-md-inline-block"
                        @toggle-comments="
                          $emit('toggle-comments-for-reference', $event)
                        "
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="col-shrink" />

                <td>
                  <div>
                    <div
                      v-if="isAgenda"
                      v-dompurify-html="agendaItemSubtitle(item)"
                      class="small mb-2"
                    />

                    <div v-if="itemText(item).length > 0" class="mb-3">
                      <p
                        v-dompurify-html="itemText(item)"
                        class="ql-editor ql-viewer p-0 overflow-hidden"
                        :class="{ 'ql-viewer-raw': !isMinutes }"
                      />
                    </div>

                    <p
                      v-if="
                        showHandledInquiries &&
                        item.subtitle === 'follow_up_inquiries' &&
                        this.isMinutes
                      "
                    >
                      {{ $t("models.inquiry.appendix") }}.
                    </p>

                    <decisions-follow-up
                      v-if="item.subtitle == 'follow_up_decisions'"
                      :item-id="item.id"
                    />

                    <tasks-follow-up
                      v-if="item.subtitle == 'follow_up_tasks'"
                      :item-id="item.id"
                    />

                    <reports-follow-up
                      v-if="item.subtitle == 'follow_up_reports'"
                      :item-id="item.id"
                    />

                    <meetings-follow-up
                      v-if="item.subtitle == 'follow_up_meetings'"
                      :item-id="item.id"
                    />

                    <div
                      v-for="decision in decisions(item)"
                      :key="`item-decision-${decision.id}`"
                      class="mb-3 page-break-avoid"
                    >
                      <h4 class="d-flex align-items-center mb-1">
                        {{
                          isAgenda
                            ? $t(
                                "models.item.decision_suggestion",
                                company.locale
                              )
                            : $t(
                                "activerecord.models.decision.one",
                                company.locale
                              )
                        }}
                        <small class="text-muted ml-1">
                          {{
                            `(${decision.human_reference_prefix_part}${meeting.number}-${decisionNumber(decision)})`
                          }}
                        </small>
                      </h4>

                      <p
                        v-if="decision.description"
                        v-dompurify-html="decision.description"
                        class="text-pre-wrap mb-0"
                      />

                      <small v-if="decision.user_id || decision.due_at">
                        <template v-if="decision.user_name">
                          <strong>
                            {{
                              $t(
                                "activerecord.attributes.decision.assigned_to",
                                company.locale
                              )
                            }}:
                          </strong>

                          {{ decision.user_name }}

                          <template v-if="decision.due_at">
                            <span>•</span>
                          </template>
                        </template>

                        <template v-if="decision.due_at">
                          <strong>
                            {{
                              $t(
                                "activerecord.attributes.decision.due_at",
                                company.locale
                              )
                            }}:
                          </strong>
                          {{
                            formatDateInStockholm(
                              new Date(decision.due_at),
                              "date",
                              company.locale
                            )
                          }}
                        </template>
                      </small>
                    </div>

                    <div
                      v-for="task in tasks(item)"
                      :key="`item-task-${task.id}`"
                      class="mb-3 page-break-avoid"
                    >
                      <h4 class="mb-1">
                        {{
                          isAgenda
                            ? $t("models.item.task_suggestion", company.locale)
                            : $t("activerecord.models.task.one", company.locale)
                        }}
                      </h4>

                      <p
                        v-dompurify-html="task.description"
                        class="text-pre-wrap mb-0"
                      />

                      <small v-if="task.user_id || task.due_at">
                        <template v-if="task.user_name">
                          <strong>
                            {{
                              $t(
                                "activerecord.attributes.task.assigned_to",
                                company.locale
                              )
                            }}:
                          </strong>

                          {{ task.user_name }}

                          <template v-if="task.due_at">
                            <span>•</span>
                          </template>
                        </template>

                        <template v-if="task.due_at">
                          <strong>
                            {{
                              $t(
                                "activerecord.attributes.task.due_at",
                                company.locale
                              )
                            }}:
                          </strong>
                          {{
                            formatDateInStockholm(
                              new Date(task.due_at),
                              "date",
                              company.locale
                            )
                          }}
                        </template>
                      </small>
                    </div>

                    <div v-if="isAgenda && item.documents.length > 0">
                      <h5>
                        {{
                          $t(
                            "components.meetings.material.attachments.title",
                            company.locale
                          )
                        }}
                      </h5>

                      <ul class="pl-3">
                        <li
                          v-for="document in item.documents"
                          :key="`item-document-${document.id}`"
                        >
                          <document-link
                            class="d-print-none"
                            :document-id="document.id"
                            :filename="document.filename"
                            :show-icon="false"
                            :title="document.title_with_filename"
                          />

                          <div class="d-none d-print-inline">
                            <be-link
                              :href="
                                url(`/documents/${document.id}`, {
                                  company: company,
                                })
                              "
                            >
                              {{ document.title_with_filename }}
                            </be-link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>

              <tr
                v-if="withComments"
                :key="`item-comments-${item.id || item.uuid}`"
              >
                <td colspan="2">
                  <comments-list
                    :comments="
                      commentsForReference({
                        type: 'Item',
                        id: item.id,
                      })
                    "
                    :commentable-object="item"
                    show-toggle
                    use-vuex-store
                    :readonly="commentsReadOnly"
                    class="mb-4"
                  />
                </td>
              </tr>

              <tr
                v-if="withMeetingOverviewComments"
                :key="`item-overview-comments-${item.id || item.uuid}`"
              >
                <td></td>

                <td>
                  <toggle-comments-button
                    :reference="{
                      type: 'Item',
                      id: item.id,
                      number: item.number,
                    }"
                    class="btn-block d-md-none"
                    @toggle-comments="
                      $emit('toggle-comments-for-reference', $event)
                    "
                  />
                </td>
              </tr>

              <tr
                v-if="isAgenda && item.recess_in_seconds > 0"
                :key="`item-recess-${item.id || item.uuid}`"
              >
                <td></td>

                <td>
                  <div
                    class="d-flex justify-content-center align-items-center lined-heading mw-100 py-2"
                  >
                    <h4 class="text-black-50 mx-2">
                      {{
                        $t("models.item.recess_w_time", company.locale, {
                          time: formatItemRecess(item),
                        })
                      }}
                    </h4>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div
          v-if="isMinutes && selectedAttachments.length > 0"
          class="border-top pt-4 mt-4"
        >
          <h3 class="mb-2">
            {{
              $t(
                "components.meetings.material.attachments.title",
                company.locale
              )
            }}
          </h3>

          <be-table-simple>
            <thead>
              <tr>
                <th>
                  {{
                    $t(
                      "activerecord.attributes.document.filename",
                      company.locale
                    )
                  }}
                </th>

                <th>
                  {{
                    $t(
                      "components.meetings.material.attachments.item",
                      company.locale
                    )
                  }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="attachment in selectedAttachments"
                :key="`attachment-${attachment.id}`"
              >
                <td>
                  <be-link
                    v-if="attachment.linked"
                    :href="attachment.paths.document"
                  >
                    {{ attachment.title }}
                  </be-link>

                  <template v-else>{{ attachment.title }}</template>
                </td>

                <td class="col-shrink">
                  {{ attachment.item_description }}
                </td>
              </tr>
            </tbody>
          </be-table-simple>
        </div>
      </div>

      <template v-if="isMinutes && withSignatureLines">
        <div
          class="row page-break-avoid mt-5"
          :class="{ 'mb-3': !showHandledInquiries }"
        >
          <div class="col-12 col-md-4 col-print-4">
            <div
              class="pt-6 border-bottom border-top-0 border-left-0 border-right-0 border-dotted mb-2"
            />

            <strong>
              {{
                $t(
                  "views.companies.meetings.materials.meeting_footer.date_and_location",
                  company.locale
                )
              }}
            </strong>
          </div>
        </div>

        <div class="row page-break-avoid">
          <div
            v-for="attendance in signatoryAttendances"
            :key="`signatory-attendance-${attendance.id}`"
            class="col-12 col-md-4 col-print-4"
          >
            <div
              class="pt-6 border-bottom border-top-0 border-left-0 border-right-0 border-dotted mb-2"
            />

            <strong v-if="showSignatoryTitles(attendance)">
              {{ signatoryTitles(attendance) }}
            </strong>

            <strong v-else class="invisible">.</strong>

            <div>
              {{ attendance.name }}
            </div>
          </div>
        </div>
      </template>

      <div
        v-else-if="isMinutes"
        class="page-break-avoid mt-4"
        :class="{ 'mb-3': !showHandledInquiries }"
      >
        <h3 class="mb-3">
          {{
            $t(
              "components.meetings.material.attendances.signatories",
              company.locale
            )
          }}
        </h3>

        <div
          v-for="attendance in signatoryAttendances"
          :key="`signatory-attendance-${attendance.id}`"
        >
          {{
            [attendance.name, signatoryTitles(attendance, true)]
              .filter(Boolean)
              .join(" ")
          }}
        </div>
      </div>

      <div v-if="showHandledInquiries && isMinutes" class="page-break-before">
        <div class="border-top pt-4 mt-4 d-print-none" />

        <div class="d-none d-print-block">
          <h1>{{ meeting.title }} - #{{ meeting.number }}</h1>

          <h3 class="mb-3">{{ meeting.company_title }}</h3>
        </div>

        <inquiries-table :items="inquiryFollowUpItems" />

        <inquiries-summary :items="inquiryFollowUpItems" />
      </div>
    </div>

    <div v-if="autoRead" v-be-visible="observeVisibilityOptions" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import materialType from "./materialState";
import sortBy from "lodash/sortBy";
import { formatTimeDuration, formatDateInStockholm } from "@/utils/time";

import CommentsList from "@/components/shared/comments/CommentsList.vue";

import DecisionsFollowUp from "./display/DecisionsFollowUp.vue";
import ReportsFollowUp from "./display/ReportsFollowUp.vue";
import MeetingsFollowUp from "./display/MeetingsFollowUp.vue";
import TasksFollowUp from "./display/TasksFollowUp.vue";
import InquiriesTable from "./display/InquiriesTable.vue";
import InquiriesSummary from "./display/InquiriesSummary.vue";
import MinutesAttendanceDisplay from "./MinutesAttendanceDisplay.vue";
import AgendaInvitationsDisplay from "./AgendaInvitationsDisplay.vue";
import ToggleCommentsButton from "../overview/ToggleCommentsButton.vue";

const AUTO_READ_AFTER_SECONDS = 1 * 1000; // 1 second

export default {
  components: {
    AgendaInvitationsDisplay,
    CommentsList,
    DecisionsFollowUp,
    ReportsFollowUp,
    MeetingsFollowUp,
    TasksFollowUp,
    InquiriesTable,
    InquiriesSummary,
    MinutesAttendanceDisplay,
    ToggleCommentsButton,
  },

  mixins: [materialType],

  props: {
    withComments: {
      type: Boolean,
      default: false,
    },

    withMeetingOverviewComments: {
      type: Boolean,
      default: false,
    },

    autoRead: {
      type: Boolean,
      required: false,
      default: false,
    },

    withSignatureLines: {
      type: Boolean,
      required: false,
      default: true,
    },

    commentsReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["reading-updated", "toggle-comments-for-reference"],

  data() {
    return {
      observeVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        throttle: AUTO_READ_AFTER_SECONDS,
      },
    };
  },

  computed: {
    ...mapGetters("material", [
      "items",
      "loading",
      "material",
      "formInvalid",
      "totalDuration",
      "itemStartTimes",
      "decisionNumbers",
    ]),

    ...mapGetters({
      attachments: "material/attachments",
      attendances: "attendances/attendances",
      company: "company/getCompany",
      meeting: "material/meeting",
      users: "company/users",
      commentsForReference: "comments/getCommentsForReference",
      inquiryGetSummary: "inquiries/getSummary",
    }),

    meetingSeriesEnabled() {
      return (
        this.$platform.features.meeting_series &&
        this.flipperFlag("meeting_series_flipper")
      );
    },

    inquirySummary() {
      return this.inquiryGetSummary({ meeting: this.meeting });
    },

    inquiryFollowUpItems() {
      return this.items.filter(
        (item) => item.subtitle == "follow_up_inquiries"
      );
    },

    showHandledInquiries() {
      return (
        this.inquiryFollowUpItems.length > 0 && this.inquirySummary.handled > 0
      );
    },

    attendancesWithNotes() {
      return [...this.regularsAndChairman, ...this.externals].filter(
        (attendance) => attendance.notes
      );
    },

    regularsAndChairman() {
      return this.attendances.filter((attendance) => {
        return ["chairman", "regular"].includes(attendance.function);
      });
    },

    absent() {
      return this.attendances.filter((attendance) => {
        return ["absent"].includes(attendance.attending);
      });
    },

    alternate() {
      return this.attendances.filter((attendance) => {
        return ["alternate"].includes(attendance.function);
      });
    },

    externals() {
      return this.attendances.filter((attendance) => {
        return ["external"].includes(attendance.function);
      });
    },

    meetingDuration() {
      if (this.totalDuration === 0) {
        return "-";
      } else {
        return formatTimeDuration(this.totalDuration, this.company.locale);
      }
    },

    formattedDateRange() {
      const startAt = new Date(this.meeting.start_at);
      const endAt = new Date(this.meeting.end_at);

      if (startAt.getDate() == endAt.getDate()) {
        return `${formatDateInStockholm(
          new Date(this.meeting.start_at),
          "dateTime",
          this.company.locale
        )} - ${formatDateInStockholm(
          new Date(this.meeting.end_at),
          "time",
          this.company.locale
        )}`;
      } else {
        return `${formatDateInStockholm(
          new Date(this.meeting.start_at),
          "dateTime",
          this.company.locale
        )} - ${formatDateInStockholm(
          new Date(this.meeting.end_at),
          "dateTime",
          this.company.locale
        )}`;
      }
    },

    scheduledDurationInSeconds() {
      const start = new Date(this.meeting.start_at);
      const end = new Date(this.meeting.end_at);
      const durationInMs = end - start;
      return durationInMs / 1000;
    },

    scheduledIsTooShort() {
      return this.scheduledDurationInSeconds < this.totalDuration;
    },

    selectedAttachments() {
      return this.attachments.filter((attachment) => {
        return attachment.selected;
      });
    },

    signatoryAttendances() {
      const signatories = this.attendances.filter(
        (attendance) => attendance.signatory
      );

      return sortBy([...signatories], (signatory) => {
        if (signatory.function === "chairman") {
          return -1;
        } else if (signatory.secretary) {
          return 0;
        } else if (signatory.reviewer) {
          return 1;
        } else {
          return signatory.id + 1;
        }
      });
    },

    companyLogoUrl() {
      return this.$currentCompany.logo_url;
    },

    hasItemDuration() {
      return this.items.some((item) => item.duration_in_seconds > 0);
    },
  },

  mounted() {
    if (!this.withMeetingOverviewComments) {
      const comments = [];
      this.items.forEach((item) => {
        comments.push(...item.comments);
      });

      this.SET_COMMENTS(comments);
    }
  },

  methods: {
    ...mapMutations("comments", ["SET_COMMENTS"]),

    formatDateInStockholm,

    formatItemDuration(item) {
      if (item.duration_in_seconds === 0) {
        return null;
      }
      return formatTimeDuration(item.duration_in_seconds, this.company.locale);
    },

    formatItemRecess(item) {
      return formatTimeDuration(item.recess_in_seconds, this.company.locale);
    },

    itemStartTime(item) {
      return this.itemStartTimes[item.id];
    },

    rapporteur(item) {
      if (item.user_id) {
        const user = this.users.find((user) => user.id === item.user_id);
        return user?.name;
      }
    },

    decisions(item) {
      const decisions = this.$store.getters["material/getItemDecisions"](item);
      if (this.isAgenda) {
        return decisions.filter((decision) => decision.include_in_agenda);
      } else {
        return decisions;
      }
    },

    showSignatoryTitles(attendance) {
      return (
        attendance.secretary ||
        attendance.reviewer ||
        attendance.function === "chairman"
      );
    },

    signatoryTitles(attendance, withParenthesis = false) {
      let titles = [];

      if (attendance.secretary) {
        titles.push(
          this.$t(
            "companies.materials.meeting_footer.secretary",
            this.company.locale
          )
        );
      }

      if (attendance.reviewer) {
        titles.push(
          this.$t(
            "companies.materials.meeting_footer.reviewer",
            this.company.locale
          )
        );
      }

      if (attendance.function === "chairman") {
        titles.push(
          this.$t(
            "companies.materials.meeting_footer.chairman",
            this.company.locale
          )
        );
      }

      if (titles.length > 0 && withParenthesis) {
        return ["(", titles.join(", "), ")"].join("");
      }

      return titles.join(", ");
    },

    tasks(item) {
      const tasks = this.$store.getters["material/getItemTasks"](item);
      return tasks.filter((task) => task.include_in_material);
    },

    itemText(item) {
      const text = this.isMinutes ? item.notes : item.description;
      return text || "";
    },

    async visibilityChanged(isVisible) {
      if (this.autoRead && isVisible) {
        try {
          const { data } = await axios.post(
            this.url(`/meetings/materials/${this.material.id}/readings`)
          );
          this.$emit("reading-updated", data);
        } catch (e) {
          this.handleError(e);
        }
      }
    },

    decisionNumber(decision) {
      return this.decisionNumbers[decision.id];
    },

    agendaItemSubtitle(item) {
      const parts = [];
      if (this.rapporteur(item)) {
        parts.push(this.rapporteur(item));
      }

      if (item.subtitle) {
        parts.push(
          this.$t(`models.item.subtitles.${item.subtitle}`, this.company.locale)
        );
      }

      if (this.formatItemDuration(item)) {
        parts.push(this.formatItemDuration(item));
      }

      if (this.hasItemDuration) {
        parts.push(
          this.formatDateInStockholm(
            new Date(this.itemStartTime(item)),
            "time",
            this.company.locale
          )
        );
      }

      return parts.join(" • ");
    },
  },
};
</script>
