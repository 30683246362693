<template>
  <be-modal
    :id="id"
    ref="modal"
    body-class="be-confirm-modal text-center p-4"
    :no-close-on-esc="noCloseOnEscape"
    :no-close-on-backdrop="noCloseOnBackdrop"
    no-footer
    no-header
    centered
    @close="handleCancel"
    @hidden="onHidden"
  >
    <div :class="iconWrapperClasses">
      <i :class="iconClasses" />
    </div>

    <h3 v-if="title" class="mt-4 mb-2 text-break">{{ title }}</h3>

    <h5 v-if="description" class="font-weight-normal">{{ description }}</h5>

    <div v-if="confirmWithInput" class="pt-3 text-left">
      <label :for="`${id}-input`" class="font-weight-semibold">
        <i18n-t
          keypath="components.shared.confirm_modal.input_label"
          scope="global"
        >
          <template #validInput>
            <code>{{ confirmWithInput }}</code>
          </template>
        </i18n-t>
      </label>

      <be-form-input
        :id="`${id}-input`"
        v-model="inputText"
        autofocus
        trim
        @keyup.enter="onEnter"
      />
    </div>

    <slot />

    <div class="d-flex flex-column flex-md-row gap-3 mt-5 pt-2">
      <be-button
        v-if="!confirmButtonOnly"
        class="order-2 order-md-1"
        variant="light"
        size="lg"
        block
        @click="handleCancel"
      >
        {{ localCancelButtonText }}
      </be-button>

      <be-button
        class="order-1 order-md-2 text-nowrap"
        :loading="loading"
        :disabled="!inputTextValid"
        :variant="variant"
        size="lg"
        block
        @click="handleConfirm"
      >
        {{ localConfirmButtonText }}
      </be-button>
    </div>
  </be-modal>
</template>

<script>
import { generateId } from "@/utils/id";
import { router } from "@inertiajs/vue3";

export default {
  name: "BeConfirmModal",

  props: {
    cancelButtonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.cancel")
    },

    confirmButtonText: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.ok")
    },

    confirmButtonOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    confirmWithInput: {
      type: String,
      required: false,
      default: undefined,
    },

    description: {
      type: String,
      required: false,
      default: "",
    },

    icon: {
      type: String,
      required: false,
      default: undefined,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-confirm-modal"),
    },

    noCloseOnBackdrop: {
      type: Boolean,
      required: false,
      default: false,
    },

    noCloseOnEscape: {
      type: Boolean,
      required: false,
      default: false,
    },

    stayOpenAfterConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },

    variant: {
      type: String,
      required: false,
      default: "primary",

      validator: (value) => {
        return ["primary", "danger"].includes(value);
      },
    },
  },

  emits: ["confirm", "cancel", "close"],

  data() {
    return {
      loading: false,
      inputText: "",
    };
  },

  computed: {
    iconClasses() {
      const defaultIcons = {
        primary: "fa-question-circle",
        danger: "fa-exclamation-triangle",
      };

      const icon = this.icon || defaultIcons[this.variant];

      return ["fal", icon];
    },

    iconWrapperClasses() {
      return [
        "be-confirm-modal-icon",
        `bg-${this.variant}-light`,
        `text-${this.variant}`,
        "d-inline-flex",
        "align-items-center",
        "mt-2",
        "p-3",
        "rounded-circle",
      ];
    },

    inputTextValid() {
      if (!this.confirmWithInput) {
        return true;
      }

      return this.inputText === this.confirmWithInput;
    },

    localCancelButtonText() {
      return this.cancelButtonText || this.$t("buttons.titles.cancel");
    },

    localConfirmButtonText() {
      return this.confirmButtonText || this.$t("buttons.titles.ok");
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
      this.$refs.modal.hide();
    },

    handleConfirm() {
      this.$emit("confirm");

      if (this.stayOpenAfterConfirm) {
        this.loading = true;

        // If Inertia is successful to navigate to a new page, we hide the modal.
        router.on("success", (event) => {
          if (event.detail.page.url !== window.location.href) {
            this.$refs.modal.hide();
          }
        });
      } else {
        this.$refs.modal.hide();
      }
    },

    onEnter() {
      if (this.inputTextValid) {
        this.handleConfirm();
      }
    },

    onHidden() {
      this.inputText = "";
      this.loading = false;
    },

    show() {
      this.$refs.modal.show();
    },
  },
};
</script>
