<template>
  <div>
    <be-button
      v-if="showToggle"
      variant="link"
      class="pl-0 mb-2"
      :disabled="readonly && visibleCommentsCount === 0"
      @click="toggleComments"
    >
      <template v-if="open">
        {{ $t("components.shared.comments.close_comments") }}
      </template>

      <template v-else>
        {{ showCommentsText }}
      </template>
    </be-button>

    <div v-show="open">
      <template v-if="loading">
        <div class="px-4 py-2">
          <div class="d-flex mb-1">
            <be-skeleton class="mr-2" size="2.5rem" />

            <div class="w-25">
              <be-skeleton class="mt-1" />

              <be-skeleton height="25%" />
            </div>
          </div>

          <be-skeleton />

          <be-skeleton />
        </div>

        <div class="px-4 py-2">
          <div class="d-flex mb-1">
            <be-skeleton class="mr-2" size="2.5rem" />

            <div class="w-25">
              <be-skeleton class="mt-1" />

              <be-skeleton height="25%" />
            </div>
          </div>

          <be-skeleton />

          <be-skeleton />
        </div>
      </template>

      <template v-else>
        <template v-if="hideCommentsOlderThan && oldItems.length > 0">
          <template v-if="displayOlderComments">
            <component
              :is="item.type"
              v-for="item in oldItems"
              :key="`${item.type}-${item.id}`"
              :item="item"
              :readonly="readonly"
              :class="{ 'border-bottom-0': items.length === 1 }"
              @update="patchComment"
              @remove="removeComment"
            />
          </template>

          <div
            v-if="hideCommentsOlderThan"
            class="text-center small border border-top-0 border-right-0 border-bottom-1 border-left-0 py-2"
          >
            <be-link
              v-if="hideCommentsOlderThan"
              @click="displayOlderComments = !displayOlderComments"
            >
              {{
                displayOlderComments
                  ? `${toggleCommentsTexts.hide} (${oldItems.length})`
                  : `${toggleCommentsTexts.show} (${oldItems.length})`
              }}
            </be-link>
          </div>

          <component
            :is="item.type"
            v-for="item in newItems"
            :key="`${item.type}-${item.id}`"
            :item="item"
            :readonly="readonly"
            :class="{ 'border-bottom-0': items.length === 1 }"
            @update="patchComment"
            @remove="removeComment"
          />
        </template>

        <template v-else>
          <component
            :is="item.type"
            v-for="item in items"
            :key="`${item.type}-${item.id}`"
            :item="item"
            :readonly="readonly"
            :class="{ 'border-bottom-0': items.length === 1 }"
            @update="patchComment"
            @remove="removeComment"
          />
        </template>

        <new-comment
          v-if="!readonly"
          :comment="newComment"
          :comment-button-size="commentButtonSize"
          @save="createComment"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Activity from "./SingleActivity.vue";
import Comment from "./SingleComment.vue";
import NewComment from "./NewComment.vue";
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  components: {
    Activity,
    Comment,
    NewComment,
  },

  props: {
    comments: {
      type: Array,
      default: () => [],
    },

    activities: {
      type: Array,
      default: () => [],
    },

    showToggle: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    commentableObject: {
      type: Object,
      required: true,
    },

    displayDeleted: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideActionButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    commentCountEvent: {
      type: String,
      required: false,
      default: null,
    },

    commentButtonSize: {
      type: String,
      required: false,
      default: "",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    useVuexStore: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideCommentsOlderThan: {
      type: [String, Date],
      required: false,
      default: null,
    },

    toggleCommentsTexts: {
      type: Object,
      required: false,

      default: () => ({
        hide: "components.shared.comments.close_comments",
        show: "components.shared.comments.show_comments.other",
      }),
    },
  },

  emits: ["comment-added", "comment-updated"],

  data() {
    return {
      open: !this.showToggle,
      newComment: { body: "" },
      localComments: this.cloneDeep(this.comments),
      displayOlderComments: false,
    };
  },

  computed: {
    ...mapGetters({
      allActivities: "activities/getFilteredActivities",
    }),

    visibleCommentsCount() {
      return this.items.filter((item) => !item.deleted_at).length;
    },

    commentsTitle() {
      if (!this.visibleCommentsCount) {
        return "zero";
      }

      return this.visibleCommentsCount > 1 ? "other" : "one";
    },

    items() {
      let comments = this.localComments.map((comment) => ({
        ...comment,
        type: "Comment",
      }));

      return [...comments, ...this.allActivities].sort((a, b) =>
        a.created_at > b.created_at ? 1 : -1
      );
    },

    newItems() {
      if (this.hideCommentsOlderThan) {
        return this.items.filter(
          (item) =>
            new Date(item.created_at) >= new Date(this.hideCommentsOlderThan)
        );
      } else {
        return [];
      }
    },

    oldItems() {
      if (this.hideCommentsOlderThan) {
        return this.items.filter(
          (item) =>
            new Date(item.created_at) < new Date(this.hideCommentsOlderThan)
        );
      } else {
        return [];
      }
    },

    showCommentsText() {
      if (this.readonly) {
        return this.$t(
          `components.shared.comments.read_only_comments.${this.commentsTitle}`,
          {
            count: this.visibleCommentsCount,
          }
        );
      } else {
        return this.$t(
          `components.shared.comments.show_comments.${this.commentsTitle}`,
          {
            count: this.visibleCommentsCount,
          }
        );
      }
    },
  },

  watch: {
    comments: {
      handler(value) {
        this.maybeEmitCommentCount();
        this.localComments = this.cloneDeep(value);
      },

      deep: true,
    },
  },

  mounted() {
    let activities = [];

    if (this.activities) {
      activities = this.activities;
    } else if (
      Object.prototype.hasOwnProperty.call(
        this.commentableObject,
        "activities"
      ) &&
      this.commentableObject.activities.length > 0
    ) {
      activities = this.commentableObject.activities;
    }

    let newActivities = activities.map((activity) => ({
      ...activity,
      type: "Activity",
    }));

    this.$store.dispatch("activities/setActivities", newActivities);
  },

  methods: {
    ...mapMutations({
      addComment: "comments/ADD_COMMENT",
      updateComment: "comments/UPDATE_COMMENT",
    }),

    toggleComments() {
      this.open = !this.open;
    },

    async createComment(comment) {
      try {
        const body = {
          sgid: this.commentableObject.sgid,
          body: comment.body,
        };

        if (!this.commentableObject.sgid) {
          body.commented_type = this.commentableObject.type;
          body.commented_id = this.commentableObject.id;
        }

        let url = "";

        if (this.$currentAdminPanel?.id) {
          url = this.url("comments", {
            admin_panel_id: this.$currentAdminPanel.id,
          });
        } else {
          url = this.url("/comments", {
            nanoid:
              this.commentableObject.company_id || this.$currentCompany.id,
          });
        }

        const response = await axios.post(url, {
          comment: body,
        });

        this.handleAddComment(response.data);

        this.newComment = {
          body: "",
        };
      } catch (error) {
        if (error.response.status === 422) {
          this.newComment = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    async patchComment(comment) {
      try {
        const response = await axios.patch(
          this.url(`/comments/${comment.id}`),
          {
            comment: {
              body: comment.body,
            },
          }
        );

        this.handleUpdateComment(response.data);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.handleUpdateComment(error.response.data);
        } else {
          this.handleError(error);
        }
      }
    },

    async removeComment(comment) {
      try {
        const response = await axios.delete(
          this.url(`/comments/${comment.id}`)
        );

        this.handleUpdateComment(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    maybeEmitCommentCount() {
      if (this.commentCountEvent !== null) {
        const nonDeletedComments = this.localComments.filter(
          (comment) => comment.deleted_at === null
        );
        EventBus.emit(this.commentCountEvent, nonDeletedComments.length);
      }
    },

    handleAddComment(comment) {
      if (this.useVuexStore) {
        this.addComment(comment);
      } else {
        this.localComments.push(comment);
      }

      this.$emit("comment-added", comment);
    },

    handleUpdateComment(comment) {
      if (this.useVuexStore) {
        this.updateComment(comment);
      } else {
        const index = this.localComments.findIndex((c) => c.id === comment.id);

        this.localComments[index] = comment;
      }

      this.$emit("comment-updated", comment);
    },
  },
};
</script>
