import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { parseISO } from "date-fns/parseISO";
import { handleError } from "@/utils/error-handling";
import { compareText } from "@/utils/text-utils";

export const state = {
  attendances: [],
  pendingAttendances: [],
  newAttendance: {},
  attendanceTemplate: {},
  hasLoaded: false,
  loading: [],
};

export const getters = {
  allAttendances: (state, getters) =>
    state.attendances.concat(getters.pendingAttendances),

  // Sort by name and put attendances with users first
  attendances: (state) => {
    const attendancesWithUser = [];
    const attendancesWithoutUser = [];
    [...state.attendances]
      .sort((a, b) => compareText(a, b, "name"))
      .forEach((attendance) => {
        if (attendance.user_id) {
          attendancesWithUser.push(attendance);
        } else {
          attendancesWithoutUser.push(attendance);
        }
      });

    return attendancesWithUser.concat(attendancesWithoutUser);
  },

  attendancesWithUsers: (state) =>
    state.attendances.filter((attendance) => attendance.user_id),

  pendingAttendances: (state) =>
    [...state.pendingAttendances].sort((a, z) => (a.name > z.name ? 1 : -1)),

  newAttendance: (state) => state.newAttendance,
  attendanceTemplate: (state) => state.attendanceTemplate,
  hasLoaded: (state) => state.hasLoaded,
  loading: (state) => state.loading,

  hasWarnings: (state) => {
    return (
      !state.attendances.some((attendance) => attendance.secretary) ||
      !state.attendances.some((attendance) => attendance.reviewer)
    );
  },

  lastUpdatedAt: (state) => {
    let maxDate = null;
    state.attendances.forEach((attendance) => {
      const date = parseISO(attendance.updated_at);
      if (maxDate == null || date > maxDate) {
        maxDate = date;
      }
    });

    return maxDate;
  },

  anythingLoading: (state) => {
    return state.loading.length > 0;
  },

  hasSecretaryAndReviewers: (state) => {
    const reviewers = state.attendances.some((attendance) => {
      return attendance.reviewer;
    });

    const secretary = state.attendances.some((attendance) => {
      return attendance.secretary;
    });

    return reviewers && secretary;
  },
};

export const actions = {
  async fetchAttendances(context, { meeting, attendanceTable = false }) {
    let url = `${meeting.paths.base}/attendances`;
    if (attendanceTable) {
      url += "?attendance_table=true";
    }
    try {
      const response = await axios.get(url);
      context.commit("loadAttendances", response.data || []);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchAttendanceTemplate(context, meeting) {
    const { attendanceTemplate } = context.getters;
    if (isEmpty(attendanceTemplate)) {
      try {
        const response = await axios.get(
          `${meeting.paths.base}/attendances/new`
        );
        await context.commit("setAttendanceTemplate", response.data);
      } catch (error) {
        handleError(error);
      }
    }
  },

  async loadNewAttendance(context, meeting) {
    await context.dispatch("fetchAttendanceTemplate", meeting);
    context.commit("setNewAttendance");
  },

  async addAttendance(context, { meeting, attendance }) {
    const response = await axios.post(attendance.paths.index, {
      attendance: omit(attendance, "paths"),
    });

    if (attendance.secretary) {
      context.dispatch("fetchAttendances", { meeting, attendanceTable: true });
    } else {
      context.commit("addAttendance", response.data);
    }
  },

  async updateAttendance(context, { attendance, meeting }) {
    try {
      let { data } = await axios.patch(attendance.paths.base, {
        attendance: omit(attendance, "paths"),
      });

      const { changed_fields, ...updatedAttendance } = data;

      if (
        changed_fields &&
        (changed_fields.includes("secretary") ||
          changed_fields.includes("chairman"))
      ) {
        context.commit("updateAttendance", updatedAttendance);
        await context.dispatch("fetchAttendances", {
          meeting,
          attendanceTable: true,
        });
      } else {
        context.commit("updateAttendance", updatedAttendance);
      }
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setNotLoading", attendance.id);
    }
  },

  async removeAttendance(context, { attendance, meeting }) {
    try {
      await axios.delete(attendance.paths.base);
      context.dispatch("fetchAttendances", {
        meeting,
        attendanceTable: true,
      });
    } catch (error) {
      handleError(error, { message: "Could not remove attendance" });
    }
  },
};

export const mutations = {
  loadAttendances(state, attendances) {
    let persisted = [];
    let pending = [];
    attendances.forEach((attendance) => {
      if (attendance.id) {
        persisted.push(attendance);
      } else {
        pending.push(attendance);
      }
    });

    state.attendances = persisted;
    state.pendingAttendances = pending;
    state.hasLoaded = true;
  },

  addAttendance(state, attendance) {
    // Find the first index used by a non-user attendance when adding an attendance with a user.
    let index = -1;
    if (attendance.user_id) {
      index = state.attendances.findIndex((existing) => !existing.user_id);
    }

    if (index > -1) {
      state.attendances.splice(index, 0, attendance);
    } else {
      state.attendances.push(attendance);
    }

    index = state.pendingAttendances.findIndex(
      (existing) => existing.user_id && existing.user_id == attendance.user_id
    );
    if (index > -1) {
      state.pendingAttendances.splice(index, 1);
    }
  },

  updateAttendance(state, attendance) {
    let index = state.attendances.findIndex(
      (existing) => existing.id == attendance.id
    );
    if (index > -1) {
      state.attendances[index] = attendance;
    }
  },

  removeAttendance(state, attendance) {
    let index = state.attendances.findIndex(
      (existing) => existing.id === attendance.id
    );
    if (index > -1) {
      state.attendances.splice(index, 1);
    }
  },

  setAttendanceTemplate(state, template) {
    state.attendanceTemplate = template;
  },

  setLoading(state, { identifier, lock }) {
    const index = state.loading.findIndex(
      (existing) => existing.identifier === identifier
    );

    if (index > -1) {
      state.loading[index].lock = lock || false;
    } else {
      state.loading.push({
        identifier: identifier,
        lock: lock || false,
      });
    }
  },

  setNotLoading(state, identifier) {
    const index = state.loading.findIndex(
      (existing) => existing.identifier === identifier
    );

    if (index > -1) {
      state.loading.splice(index, 1);
    }
  },

  setNewAttendance(state, attendance) {
    if (attendance) {
      state.newAttendance = attendance;
    } else {
      state.newAttendance = cloneDeep(state.attendanceTemplate);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
