import { createApp, defineComponent } from "vue";
import BeConfirmModal from "../components/shared/BeConfirmModal.vue";
import { router } from "@inertiajs/vue3";

// Import store, components and directives that are used in the BeConfirmModal component
// This is needed because we create a new app instance that is isolated from the main app
import store from "../store";
import i18n from "../i18n";
import BeButton from "../components/shared/BeButton.vue";
import BeFormInput from "../components/shared/BeFormInput.vue";
import BeModal from "../components/shared/BeModal.vue";
import BeSpinner from "../components/shared/BeSpinner.vue";
import BeTeleport from "../components/shared/BeTeleport.vue";
import tooltip from "../directives/tooltip";

const mountConfirmModal = (options) => {
  return new Promise((resolve) => {
    // Create a component instance for the BeConfirmModal component
    const ConfirmModal = defineComponent(BeConfirmModal);

    // Create and prepend a container element to the body
    const container = document.createElement("div");
    document.body.prepend(container);

    // Create the app instance
    const app = createApp(ConfirmModal, {
      ...options,

      onConfirm: () => {
        resolve(true);

        if (options.stayOpenAfterConfirm) {
          router.on("success", (event) => {
            if (event.detail.page.url !== window.location.href) {
              // Unmount the app after a short delay to allow the modal to close
              setTimeout(() => {
                app.unmount();
                container.remove();
              }, 500);
            }
          });
        } else {
          // Unmount the app after a short delay to allow the modal to close
          setTimeout(() => {
            app.unmount();
            container.remove();
          }, 500);
        }
      },

      onCancel: () => {
        resolve(false);

        // Unmount the app after a short delay to allow the modal to close
        setTimeout(() => {
          app.unmount();
          container.remove();
        }, 500);
      },
    });

    // Register components and directives that are used in the BeConfirmModal component
    // This is needed because we create a new app instance that is isolated from the main app
    app.use(store);
    app.use(i18n);
    app.component("BeButton", BeButton);
    app.component("BeFormInput", BeFormInput);
    app.component("BeModal", BeModal);
    app.component("BeSpinner", BeSpinner);
    app.component("BeTeleport", BeTeleport);
    app.directive("be-tooltip", tooltip);

    // Mount the app to the container
    const instance = app.mount(container);

    // Show the modal
    instance.show();
  });
};

const ConfirmPlugin = {
  install(app) {
    app.config.globalProperties.$confirm = mountConfirmModal;
  },

  mountConfirmModal,
};

export default ConfirmPlugin;
